import {
  Toast
} from 'vant'
import axios from 'axios'
import Vue from 'vue'

// 请求拦截器
axios.interceptors.request.use(config => {
  return config
}, err => {
  return Promise.reject(err)
})

// 响应拦截器即异常处理
axios.interceptors.response.use(response => {
  if (response.data.code === 3 || response.data.code === 4) {
    setTimeout(() => {
      if (window.JsHook) {
        window.JsHook.logout()
      } else if (window.webkit) {
        window.webkit.messageHandlers.logout.postMessage('')
      } else {
        // window.parent.postMessage({
        //   cmd: 'login'
        // }, '*')
      }
    }, 500)
  }
  return response
}, err => {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        err.message = err.response.data || '错误请求'
        break
      case 401:
        err.message = err.response.data || '未授权，请重新登录'
        break
      case 403:
        err.message = '您没有访问该内容的权限！'
        break
      case 404:
        err.message = '接口未找到！'
        break
      case 405:
        err.message = '数据接口不存在，请联系系统管理员进行排查！'
        break
      case 408:
        err.message = '数据请求超时，请检查您的网络是否有问题！'
        break
      case 500:
        err.message = '您请求的数据接口出错，请检查接口代码！'
        break
      case 501:
        err.message = '网络未实现'
        break
      case 502:
        err.message = '网络错误'
        break
      case 503:
        err.message = '服务不可用'
        break
      case 504:
        err.message = '网络超时'
        break
      case 505:
        err.message = 'http版本不支持该请求'
        break
      default:
        err.message = `连接错误${err.response.status}`
    }
  } else {
    err.message = '连接到服务器失败'
  }
  Toast.fail(err.message)
  return Promise.reject(err)
})

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.common['cache-control'] = 'no-cache'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

if (window.JsHook) {
  const object = JSON.parse(window.JsHook.getToken())
  sessionStorage.setItem('XR-AccessToken', object['XR-AccessToken'])
  sessionStorage.setItem('XR-RefreshToken', object['XR-RefreshToken'])
  sessionStorage.setItem('channelId', object.channelId)
} else if (window.webkit) {
  setTimeout(() => {
    window.webkit.messageHandlers.getToken.postMessage('')
  }, 200)
} else {
  window.addEventListener('message', (event) => {
    const data = event.data
    if (data.cmd === 'userinfo') {
      sessionStorage.setItem('XR-AccessToken', data.params.userInfo.accessToken)
      sessionStorage.setItem('XR-RefreshToken', data.params.userInfo.refreshToken)
      sessionStorage.setItem('channelId', data.params.userInfo.channelId)
    }
  })
}

export function fetch (options) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common.channelId = sessionStorage.getItem('channelId')
    axios.defaults.headers.common['XR-AccessToken'] = sessionStorage.getItem('XR-AccessToken')
    axios.defaults.headers.common['XR-RefreshToken'] = sessionStorage.getItem('XR-RefreshToken')
    // axios.defaults.headers.common['XR-AccessToken'] = '565d94433c904a88844e28f1d925a559'
    // axios.defaults.headers.common['XR-RefreshToken'] = 'cdd44fe5478747b4b5bea8a13d62db1b'
    // axios.defaults.headers.common.channelId = '10002'
    axios({
      url: (process.env.NODE_ENV === 'development' ? '/api' : Vue.prototype.$config.url) + options.url,
      data: options.data || null,
      method: 'post'
    }).then((rs) => {
      if (rs.data.code === 0) {
        return resolve(rs.data)
      } else if (rs.data.code === 3 || rs.data.code === 4) {
        Toast.fail(rs.data.msg ? rs.data.msg : '网络错误！')
        return resolve(rs.data)
      } else {
        Toast.fail(rs.data.msg ? rs.data.msg : '网络错误！')
        return resolve(rs.data)
      }
    }).catch((err) => {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({
        err: err
      })
    })
  })
}
