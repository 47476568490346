import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './utils/request.js'

import './assets/css/index.css'

import {
  NavBar,
  Lazyload,
  DropdownMenu,
  DropdownItem,
  Icon,
  Circle,
  Loading,
  PullRefresh,
  List,
  Cell
} from 'vant'

import overall from '@/utils/common.js'

if (window.JsHook) {
  const object = JSON.parse(window.JsHook.getToken())
  sessionStorage.setItem('XR-AccessToken', object['XR-AccessToken'])
  sessionStorage.setItem('XR-RefreshToken', object['XR-RefreshToken'])
  sessionStorage.setItem('channelId', object.channelId)
} else if (window.webkit) {
  setTimeout(() => {
    window.webkit.messageHandlers.getToken.postMessage('')
  }, 200)
} else {
  window.addEventListener('message', (event) => {
    const data = event.data
    if (data.cmd === 'userinfo') {
      sessionStorage.setItem('XR-AccessToken', data.params.userInfo.accessToken)
      sessionStorage.setItem('XR-RefreshToken', data.params.userInfo.refreshToken)
      sessionStorage.setItem('channelId', data.params.userInfo.channelId)
    }
  })
}

// 全局公共方法/变量
Object.keys(overall).forEach(key => {
  Vue.prototype['$' + key] = overall[key]
})

// config文件
Vue.prototype.$config = window.config

Vue.use(NavBar)
Vue.use(Lazyload)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Icon)
Vue.use(Circle)
Vue.use(Loading)
Vue.use(PullRefresh)
Vue.use(List)
Vue.use(Cell)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
