<template>
    <div class="loading">
        <van-loading type="spinner" color="#c9c9c9" />
    </div>
</template>

<script>
export default {
    name: "LOADING",
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>
<style lang="less" scoped>
.loading {
    position: fixed;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>