import {
  fetch
} from '@/utils/request.js'

// 贷超大全
const surpluses = {
  // 首页list
  surplusesList: '/api/market/app/loanProduct/queryPage',
  // 详情
  surplusesDetail: '/api/market/app/loanProduct/getDetail',
  // 埋点
  buryingpoint: '/api/market/app/accessRecord/handle'
}

export function HANDLEAPI (api, data) {
  return fetch({
    url: surpluses[api],
    data: data
  })
}
