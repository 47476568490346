import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '../views/layout'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: 'index',
  component: Layout,
  children: [{
    path: 'index',
    name: 'index',
    meta: {
      title: '贷超大全',
      isCache: true
    },
    component: (resolve) => require(['@/views/index/index'], resolve)
  }, {
    path: 'list',
    name: 'list',
    meta: {
      title: '贷超大全列表',
      isCache: true
    },
    component: (resolve) => require(['@/views/index/indexList'], resolve)
  }, {
    path: 'detail',
    name: 'detail',
    meta: {
      title: '贷超大全详情',
      isCache: false
    },
    component: (resolve) => require(['@/views/index/indexDetail'], resolve)
  }, {
    path: 'webview',
    name: 'webview',
    meta: {
      title: '贷超大全页面',
      isCache: false
    },
    component: (resolve) => require(['@/views/index/indexWebview'], resolve)
  }]
}]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
