import Vue from 'vue'
import Vuex from 'vuex'
import {
  HANDLEAPI
} from '../api/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    LOADING: false
  },
  mutations: {
    // 显示LOADING状态
    showLoading (state, payload) {
      state.LOADING = true
    },
    // 隐藏LOADING状态
    hideLoading (state, payload) {
      state.LOADING = false
    }
  },
  actions: {
    Buryingpoint ({
      commit
    }, data) {
      HANDLEAPI('buryingpoint', data)
    }
  },
  modules: {}
})
