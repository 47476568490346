<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted () {
    // document.body.addEventListener(
    //   'touchmove',
    //   function (e) {
    //     e.preventDefault() // 阻止默认的处理方式(阻止下拉滑动的效果)
    //   },
    //   { passive: false }
    // )
  }
}
</script>
<style lang="less">
body {
  width: 100vw;
  height: 100vh;
}
#app {
  height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
