<template>
  <div class="layout">
    <keep-alive>
      <router-view v-if="$route.meta.isCache"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.isCache"></router-view>
    <Loading v-show="LOADING"></Loading>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/components/Loading'
export default {
  name: 'Layout',
  components: { Loading },
  data () {
    return {}
  },
  computed: {
    ...mapState(['LOADING'])
  },
  mounted () {
    if (window.JsHook) {
      const object = JSON.parse(window.JsHook.getToken())
      sessionStorage.setItem('XR-AccessToken', object['XR-AccessToken'])
      sessionStorage.setItem('XR-RefreshToken', object['XR-RefreshToken'])
      sessionStorage.setItem('channelId', object.channelId)
    } else if (window.webkit) {
      setTimeout(() => {
        window.webkit.messageHandlers.getToken.postMessage('')
      }, 200)
    } else {
      window.addEventListener('message', (event) => {
        const data = event.data
        if (data.cmd === 'userinfo') {
          sessionStorage.setItem('XR-AccessToken', data.params.userInfo.accessToken)
          sessionStorage.setItem('XR-RefreshToken', data.params.userInfo.refreshToken)
          sessionStorage.setItem('channelId', data.params.userInfo.channelId)
        }
      })
    }
    // IOS获取token
    window.Listen_jf_getToken = function (res) {
      const object = JSON.parse(res)
      sessionStorage.setItem('XR-AccessToken', object.AccessToken)
      sessionStorage.setItem('XR-RefreshToken', object.RefreshToken)
      sessionStorage.setItem('channelId', object.channelId)
    }
    // 全局返回
    window.PhysicalReturn = function () {
      const routeArr = ['inxex']
      if (routeArr.includes(window.location.pathname)) {
        if (window.JsHook) {
          window.JsHook.exitApp()
        }
      } else {
        this.history.go(-1)
      }
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>
</style>
