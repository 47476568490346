const category = navigator.userAgent
const isAndroid = category.indexOf('Android') > -1 || category.indexOf('Adr') > -1 // android终端
const isiOS = !!category.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
const isAPP = window.JsHook || window.webkit

/**
 * 判断终端环境
 */
const environment = isAndroid && isAPP ? '2' : isiOS && isAPP ? '1' : '1'

/**
 * 判断当前环境
 */
const moveenvironment = isAndroid && isAPP ? '2' : isiOS && isAPP ? '1' : isAndroid ? '4' : '3'

const isWeiXin = function () {
  var ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) === 'micromessenger') {
    return true
  } else {
    return false
  }
}

/**
 * 原生公共loading隐藏
 */
const checkLoading = function () {
  if (window.JsHook) {
    window.JsHook.closeLoading()
  } else if (window.webkit && !isWeiXin()) {
    window.webkit.messageHandlers.closeLoading.postMessage('')
  }
}

export default {
  environment,
  moveenvironment,
  checkLoading,
  isWeiXin
}
